import { ChevronLeft as ChevronLeftIcon } from '@air/icons';
import { useTrackClickedActionItem } from '@air/marketing-analytics';
import { Box, BoxProps, Text } from '@air/zephyr';
import { motion, useReducedMotion } from 'framer-motion';
import { noop } from 'lodash';
import Link from 'next/link';
import { ReactNode } from 'react';

import { HeaderMobileMenuNavigationItem } from '~/old-marketing/components/Header/HeaderMobileMenu/HeaderMobileMenuNavigationItem';
import { HeaderMobileMenuPanelNavigationItem } from '~/old-marketing/components/Header/HeaderMobileMenu/HeaderMobileMenuPanelNavigationItem';

const HEADER_MOBILE_MENU_PANEL_ANIMATION_VARIANTS = {
  closed: {
    x: -24,
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
  },
  opened: {
    x: 0,
    display: 'block',
    opacity: 1,
  },
};

const HEADER_MOBILE_MENU_PANEL_REDUCED_ANIMATION_VARIANTS = {
  closed: {
    opacity: 0,
    height: '100%',
    transitionEnd: {
      display: 'none',
    },
  },
  opened: {
    height: '100%',
    opacity: 1,
  },
};

export interface HeaderMobileMenuPanelProps extends Pick<BoxProps, 'tx'> {
  isOpened: boolean;
  name: string;
  navigation: {
    children?: ReactNode;
    chunkSize?: number;
    hasDivider?: boolean;
    items?: {
      adornment?: ReactNode;
      description?: string;
      href: string;
      name: string;
    }[];
    name?: string;
  }[];
  onClose: () => void;
}

export const HeaderMobileMenuPanel = ({
  isOpened,
  name,
  navigation,
  onClose = noop,
  tx,
}: HeaderMobileMenuPanelProps) => {
  const shouldReduceMotion = useReducedMotion();
  const { trackClickedActionItem } = useTrackClickedActionItem();

  return (
    <Box
      animate={isOpened ? 'opened' : 'closed'}
      as={motion.div}
      initial={{ display: 'none', x: -24, opacity: 0 }}
      transition={{ duration: 0.2, type: 'tween' }}
      tx={{
        position: 'fixed',
        top: 80,
        right: 0,
        bottom: 0,
        left: 0,
        height: 'calc(100% - 80px)',
        backgroundColor: 'white',
        overflowY: 'scroll',
        WebkitOverflowScrolling: 'touch',
        py: 40,
        px: 32,
        ...tx,
      }}
      variants={
        shouldReduceMotion
          ? HEADER_MOBILE_MENU_PANEL_REDUCED_ANIMATION_VARIANTS
          : HEADER_MOBILE_MENU_PANEL_ANIMATION_VARIANTS
      }
    >
      <HeaderMobileMenuNavigationItem as="button" onClick={onClose} tx={{ mb: 24 }}>
        <Box as={ChevronLeftIcon} tx={{ display: 'block', width: 24, mr: 12 }} />
        {name}
      </HeaderMobileMenuNavigationItem>

      <Box tx={{ ml: 36 }}>
        {navigation?.map((newNavigation) => {
          if (!newNavigation.items) {
            return null;
          }

          return (
            <Box key={newNavigation.name} tx={{ mb: 32, 'last-child': { mb: 0 } }}>
              <Text
                tx={{
                  mb: 20,
                  color: 'pigeon900',
                  fontWeight: 'medium',
                }}
                variant="text-heading-20"
              >
                {newNavigation.name}
              </Text>

              {newNavigation?.items?.map((navigationItem) => {
                return (
                  <Link href={navigationItem.href} key={navigationItem.name} passHref>
                    <HeaderMobileMenuPanelNavigationItem
                      onClick={() => {
                        trackClickedActionItem({
                          destination: navigationItem.href,
                          destinationType: 'internal',
                          location: window.location.href,
                          name: navigationItem.name,
                          pathName: window.location.pathname,
                          zone: 'marketing',
                        });
                      }}
                      leftAdornment={navigationItem.adornment}
                    >
                      {navigationItem.name}
                    </HeaderMobileMenuPanelNavigationItem>
                  </Link>
                );
              })}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
