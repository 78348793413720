import React from 'react';

import * as StyledHeaderMobileHamburger from '~/old-marketing/components/Header/HeaderMobileHamburger.styles';
import { VisuallyHidden } from '~/old-marketing/components/primitives/VisuallyHidden';

export type HeaderMobileHamburgerProps = Pick<StyledHeaderMobileHamburger.RootProps, 'css' | 'onClick'> & {
  isOpened: boolean;
};

export const HeaderMobileHamburger = ({ css, isOpened, onClick }: HeaderMobileHamburgerProps) => {
  return (
    <StyledHeaderMobileHamburger.Root css={css} onClick={onClick}>
      <VisuallyHidden>Menu</VisuallyHidden>
      <StyledHeaderMobileHamburger.Bar animate={{ rotate: isOpened ? 45 : 0, y: isOpened ? 8 : 0 }} />
      <StyledHeaderMobileHamburger.Bar animate={{ width: isOpened ? 0 : 'auto', opacity: isOpened ? 0 : 1 }} />
      <StyledHeaderMobileHamburger.Bar animate={{ rotate: isOpened ? -45 : 0, y: isOpened ? -8 : 0 }} />
    </StyledHeaderMobileHamburger.Root>
  );
};
