import { Text } from '@air/zephyr';
import { useRef } from 'react';

import { DialogContent, DialogContentProps, DialogOverlay, DialogOverlayClose, DialogOverlayProps } from './Dialog.ui';

export interface DialogProps extends Required<Pick<DialogOverlayProps, 'isOpen' | 'onDismiss'>>, DialogContentProps {
  title: string;
  description?: string;
}

export const Dialog = ({ children, isOpen = false, onDismiss, title, description, ...restOfProps }: DialogProps) => {
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <DialogOverlay isOpen={isOpen} initialFocusRef={closeButtonRef} onDismiss={onDismiss}>
      <DialogContent aria-labelledby="dialog-title" {...restOfProps}>
        <Text as="h1" id="dialog-title" tx={{ mb: 16, color: 'jay700', textAlign: 'center' }} variant="text-heading-32">
          {title}
        </Text>
        {description && (
          <Text as="p" tx={{ mb: 24 }} variant="text-ui-16">
            {description}
          </Text>
        )}
        {children}
      </DialogContent>
      <DialogOverlayClose onClick={onDismiss} ref={closeButtonRef} />
    </DialogOverlay>
  );
};
