import { useTrackClickedActionItem } from '@air/marketing-analytics';
import { Box, Button, Text } from '@air/zephyr';
import Link from 'next/link';

import { AuthRoutes } from '~/old-marketing/constants/Routes';

export const HeaderMobileMenuSignUpBanner = () => {
  const { trackClickedActionItem } = useTrackClickedActionItem();

  return (
    <Box
      tx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: ['column', 'row'],
        justifyContent: 'space-between',
        backgroundColor: 'jay050',
        p: 24,
        borderRadius: 8,
      }}
    >
      <Text as="h6" variant="text-heading-24" tx={{ color: 'jay700', mb: [24, 0, 0], textAlign: 'center' }}>
        Get started in seconds, add your team as you go do more with your visual assets on Air!
      </Text>
      <Link href={AuthRoutes.signUp} passHref>
        <Button
          as="a"
          onClick={() => {
            trackClickedActionItem({
              destination: AuthRoutes.signUp,
              destinationType: 'internal',
              location: window.location.href,
              name: 'Sign up for free',
              pathName: window.location.pathname,
              zone: 'marketing',
            });
          }}
          size="large"
          variant="button-filled-blue"
        >
          Sign up for free
        </Button>
      </Link>
    </Box>
  );
};
