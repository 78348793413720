import { ChevronDown } from '@air/icons';
import { useTrackClickedActionItem } from '@air/marketing-analytics';
import { Box } from '@air/marketing-primitives';
import { DemoDialog } from '@air/marketing-tech-debt/src/components/dialogs/DemoDialog';
import { ChilipiperLeadsource } from '@air/plasmic-chilipiper';
import { Button } from '@air/zephyr';
import { usePreventScroll } from '@react-aria/overlays';
import { motion } from 'framer-motion';
import { isUndefined, noop } from 'lodash';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { Container } from '~/old-marketing/components/globals/Container';
import * as StyledDefaultHeader from '~/old-marketing/components/globals/headers/DefaultHeader/DefaultHeader.styles';
import { Logo } from '~/old-marketing/components/globals/Logo';
import { HeaderMenu } from '~/old-marketing/components/Header/HeaderMenu';
import { HeaderMobileHamburger } from '~/old-marketing/components/Header/HeaderMobileHamburger';
import { HeaderMobileMenu } from '~/old-marketing/components/Header/HeaderMobileMenu/HeaderMobileMenu';
import { HeaderNavigationItem } from '~/old-marketing/components/Header/HeaderNavigationItem';
import {
  HEADER_NAVIGATION,
  HeaderNavigationItem as HeaderNavigationItemType,
} from '~/old-marketing/constants/navigation';
import { AuthRoutes } from '~/old-marketing/constants/Routes';
import { HEADER, HEADER_NAVIGATION_BUTTON } from '~/old-marketing/constants/testIds';

export type DefaultHeaderProps = {
  appearance?: 'dark' | 'light';
  ctaText?: string;
  customButton?: ReactNode;
  hasAuthNavigation?: boolean;
  hasMobileNavigation?: boolean;
  hasDemoOnlyCTANavigation?: boolean;
  hasDemoOnlyCTANavigationOnMobile?: boolean;
  hasLogo?: boolean;
  hasNavigation?: boolean;
  isFixed?: boolean;
  isHiddenOnSticky?: boolean;
  leadsource?: ChilipiperLeadsource;
  router?: string;
  navigation?: HeaderNavigationItemType[];
};

export const DefaultHeader = ({
  appearance,
  customButton,
  ctaText = 'Book a demo',
  hasAuthNavigation = true,
  hasMobileNavigation = true,
  hasDemoOnlyCTANavigation = false,
  hasDemoOnlyCTANavigationOnMobile = false,
  hasLogo = true,
  hasNavigation = true,
  isFixed = false,
  isHiddenOnSticky = false,
  leadsource,
  navigation = HEADER_NAVIGATION,
  router: chilipiperRouter,
}: DefaultHeaderProps) => {
  const router = useRouter();
  const [activeMenu, setActiveMenu] = useState<number | undefined>(undefined);
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState<boolean>(false);
  const [isDemoDialogOpened, setIsDemoDialogOpened] = useState(false);
  const [stickyRef, inView, entry] = useInView();
  const isStickied = !inView && entry;
  const isMenuOpened = !isUndefined(activeMenu);
  const isLightText = appearance === 'light' && !(isStickied && isFixed) && !isMobileMenuOpened && !isMenuOpened;
  const { trackClickedActionItem } = useTrackClickedActionItem();

  /**
   * Prevents the page from scrolling when the mobile menu is opened.
   */
  usePreventScroll({ isDisabled: !isMobileMenuOpened });

  /**
   * Closes the mobile menu when a route change is completed.
   */
  useEffect(() => {
    const onMobileMenuClose = () => setIsMobileMenuOpened(false);

    router.events.on('routeChangeComplete', onMobileMenuClose);

    return () => router.events.off('routeChangeComplete', onMobileMenuClose);
  }, [router.events]);

  /**
   * This closes the mobile menu when the window is resized.
   */
  useEffect(() => {
    const onMobileMenuClose = () => {
      if (window.innerWidth > 1024) {
        setIsMobileMenuOpened(false);
      }
    };

    window.addEventListener('resize', onMobileMenuClose);

    return () => window.removeEventListener('resize', onMobileMenuClose);
  }, []);

  return (
    <>
      <Box ref={stickyRef} />
      <StyledDefaultHeader.Root
        data-testid={HEADER}
        css={{
          position: isFixed ? 'fixed' : 'absolute',
          backgroundColor: (isStickied && isFixed) || isMenuOpened ? 'white' : 'transparent',
          boxShadow: isStickied && isFixed ? '0 4px 10px rgba(0, 0, 0, 0.06)' : 'none',
        }}
      >
        <Container
          css={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            justifyContent: 'space-between',
          }}
        >
          {hasLogo && (
            <Logo
              css={{
                display: 'block',
                height: 32,
                marginRight: 32,
                color: isLightText ? '$white' : '$macaw600',
              }}
            />
          )}

          {hasNavigation && (
            <StyledDefaultHeader.Navigation
              css={{
                flexGrow: 1,
                display: 'none',
                color: isLightText ? '$white' : '$pigeon800',
                transition: 'opacity 0.2s ease',
                paddingX: 12,

                '@desktop': {
                  display: 'flex',
                },
              }}
              data-testid={HEADER_NAVIGATION}
              style={{
                opacity: isHiddenOnSticky && isStickied ? 0 : 1,
                pointerEvents: isHiddenOnSticky && isStickied ? 'none' : 'auto',
              }}
            >
              {navigation.map((navItem, index) => {
                const hasMenu = navItem.columns;

                return (
                  <HeaderNavigationItem
                    href={navItem.href}
                    key={index}
                    rightAdornment={
                      hasMenu && (
                        <Box
                          animate={{ rotate: isMenuOpened && activeMenu === index ? -180 : 0 }}
                          as={motion.div}
                          css={{ marginLeft: 4, transformOrigin: 'center' }}
                        >
                          <Box as={ChevronDown} css={{ display: 'block', width: 16 }} />
                        </Box>
                      )
                    }
                    menu={
                      navItem.columns &&
                      activeMenu === index && (
                        <HeaderMenu
                          color={navItem.color}
                          columns={navItem.columns}
                          onDemoDialogOpen={() => setIsDemoDialogOpened(true)}
                          template={navItem.template as 'enterprise' | 'product' | 'resources'}
                        />
                      )
                    }
                    name={navItem.name}
                    onBlur={hasMenu ? () => setActiveMenu(undefined) : noop}
                    onFocus={hasMenu ? () => setActiveMenu(index) : noop}
                    onMouseEnter={hasMenu ? () => setActiveMenu(index) : noop}
                    onMouseLeave={hasMenu ? () => setActiveMenu(undefined) : noop}
                  />
                );
              })}
            </StyledDefaultHeader.Navigation>
          )}

          {hasAuthNavigation && (
            <StyledDefaultHeader.Navigation
              css={{
                display: hasDemoOnlyCTANavigationOnMobile ? 'flex' : 'none',
                color: isLightText ? '$white' : '$pigeon800',

                '@desktop': {
                  display: 'flex',
                },
              }}
              data-testid={HEADER_NAVIGATION}
            >
              <HeaderNavigationItem href={AuthRoutes.login} name="Login" />

              <Button
                as="a"
                data-testid={HEADER_NAVIGATION_BUTTON}
                onClick={() => {
                  trackClickedActionItem({
                    destinationType: 'internal',
                    location: window.location.href,
                    name: 'Sign up for free',
                    pathName: window.location.pathname,
                    zone: 'marketing',
                    version: 'new-marketing-header',
                  });

                  setIsDemoDialogOpened(true);
                }}
                tx={{
                  mr: 12,
                  color: isLightText ? 'white' : undefined,
                  '&:hover:not(:disabled)': { color: isLightText ? 'white' : undefined },
                  height: 36,
                  paddingX: 12,
                }}
                variant="button-outline-blue"
              >
                Book a demo
              </Button>

              <Link href={AuthRoutes.signUp} passHref>
                <Button
                  as="a"
                  data-testid={HEADER_NAVIGATION_BUTTON}
                  onClick={() =>
                    trackClickedActionItem({
                      destination: AuthRoutes.signUp,
                      destinationType: 'internal',
                      location: window.location.href,
                      name: 'Sign up for free',
                      pathName: window.location.pathname,
                      zone: 'marketing',
                      version: 'new-marketing-header',
                    })
                  }
                  tx={{
                    height: 36,
                    paddingX: 12,
                  }}
                >
                  Sign up for free
                </Button>
              </Link>
            </StyledDefaultHeader.Navigation>
          )}

          {hasDemoOnlyCTANavigation && (
            <StyledDefaultHeader.Navigation
              css={{
                display: hasDemoOnlyCTANavigationOnMobile ? 'flex' : 'none',
                color: isLightText ? '$white' : '$pigeon800',

                '@desktop': {
                  display: 'flex',
                },
              }}
              data-testid={HEADER_NAVIGATION}
            >
              <Button
                data-testid={HEADER_NAVIGATION_BUTTON}
                data-id="book-a-demo"
                onClick={() => {
                  setIsDemoDialogOpened(true);
                }}
                tx={{
                  mr: 12,
                  color: isLightText ? 'white' : undefined,
                  '&:hover:not(:disabled)': { color: isLightText ? 'white' : undefined },
                  height: 36,
                  paddingX: 12,
                }}
                variant="button-filled-blue"
              >
                {ctaText}
              </Button>
            </StyledDefaultHeader.Navigation>
          )}

          {customButton}

          {hasMobileNavigation && (
            <HeaderMobileHamburger
              css={{
                display: 'block',

                '@desktop': {
                  display: 'none',
                },
              }}
              isOpened={isMobileMenuOpened}
              onClick={() => setIsMobileMenuOpened(!isMobileMenuOpened)}
            />
          )}
        </Container>
      </StyledDefaultHeader.Root>

      {hasMobileNavigation && <HeaderMobileMenu navigation={navigation} isOpened={isMobileMenuOpened} />}
      <DemoDialog
        id={chilipiperRouter}
        isOpen={isDemoDialogOpened}
        leadsource={leadsource}
        onDismiss={() => setIsDemoDialogOpened(false)}
      />
    </>
  );
};
