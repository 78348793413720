import {
  trackGTMEvent,
  useTrackClickedLeadFormSubmit,
  useTrackClickedLeadFormSubmit1To10,
  useTrackClickedLeadFormSubmit11Plus,
} from '@air/marketing-analytics';
import { Anchor, Box, Button, Text } from '@air/marketing-primitives';
import {
  chilipiperEmailValidationSchema,
  ChilipiperLeadsource,
  ChilipiperRouter,
  useChilipiper,
} from '@air/plasmic-chilipiper';
import { Input as DeprecatedInput, SingleSelect } from '@air/zephyr';
import { Formik } from 'formik';
import { shuffle } from 'lodash';
import { object, string } from 'yup';

import { Form } from '../../components/Form';
import { Routes } from '../../constants/routes';

const validationSchema = object({
  firstName: string().default('').required('First name is required'),
  lastName: string().default('').required('Last name is required'),
  email: chilipiperEmailValidationSchema,
  company_size: string().default('').required('Company Size is required'),
  hearAbout: string().default(''),
  hearAboutOther: string().default(''),
});

const selectStyles = { marginBottom: 24, minWidth: 'unset', '> div': { backgroundColor: 'white', borderRadius: 4 } };

export interface ChilipiperDetailedFormProps {
  buttonText?: string;
  id?: string;
  leadsource?: ChilipiperLeadsource;
  isLabelHidden?: boolean;
}
export const ChilipiperDetailedForm = ({
  buttonText = 'Book a demo',
  id,
  isLabelHidden = false,
  leadsource = ChilipiperLeadsource.Default,
}: ChilipiperDetailedFormProps) => {
  const { createLead } = useChilipiper({
    router: id || ChilipiperRouter['limit-fields-aug-22'],
    leadsource: leadsource ?? ChilipiperLeadsource.Default,
  });
  const { trackClickedLeadFormSubmit } = useTrackClickedLeadFormSubmit();
  const { trackClickedLeadFormSubmit1To10 } = useTrackClickedLeadFormSubmit1To10();
  const { trackClickedLeadFormSubmit11Plus } = useTrackClickedLeadFormSubmit11Plus();

  return (
    <Formik
      initialValues={validationSchema.cast(undefined)!}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        trackClickedLeadFormSubmit(values);

        trackGTMEvent({
          event: `Lead Form Submit`,
          eventProps: { label: 'lead form submit', category: 'enterprise', ...values },
        });

        if (values?.['company_size'] === '1-10') {
          trackClickedLeadFormSubmit1To10(values);

          trackGTMEvent({
            event: `Lead Form Submit (1-10)`,
            eventProps: { label: 'lead form submit', category: 'enterprise', ...values },
          });
        } else {
          trackGTMEvent({
            event: `Lead Form Submit (11+)`,
            eventProps: { label: 'lead form submit', category: 'enterprise', ...values },
          });
          trackClickedLeadFormSubmit11Plus(values);
        }

        createLead({
          values: {
            ...values,
          },
          onSuccess: () => {
            setSubmitting(false);
            resetForm();
          },
        });
      }}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <Form>
          <Box
            css={{
              display: 'flex',
              gap: 16,
              marginBottom: 24,
              flexDirection: 'column',

              '@laptop': {
                flexDirection: 'row',
              },
            }}
          >
            <DeprecatedInput
              autoComplete="given-name"
              label="First name"
              data-testid="CHILIPIPER_FORM_FIRST_NAME"
              name="firstName"
              required
              tx={{ flexGrow: 1, minWidth: 'unset' }}
              type="text"
              variant="field-input-smol"
            />
            <DeprecatedInput
              autoComplete="family-name"
              data-testid="CHILIPIPER_FORM_LAST_NAME"
              label="Last name"
              name="lastName"
              required
              tx={{ flexGrow: 1, minWidth: 'unset' }}
              type="text"
              variant="field-input-smol"
            />
          </Box>
          <DeprecatedInput
            autoComplete="email"
            data-testid="CHILIPIPER_FORM_EMAIL"
            isLabelHidden={isLabelHidden}
            label="Business email"
            name="email"
            placeholder="Work email address"
            required
            tx={{ width: '100%', minWidth: 'unset', marginBottom: 24 }}
            type="email"
            variant="field-input-smol"
          />
          <SingleSelect
            data-testid="CHILIPIPER_FORM_COMPANY_SIZE"
            label="Company size"
            name="company_size"
            options={[
              {
                label: '1-10',
                value: '1-10',
              },
              {
                label: '11-50',
                value: '11-50',
              },
              {
                label: '51-100',
                value: '51-100',
              },
              {
                label: '101-500',
                value: '101-500',
              },
              {
                label: '501-1000',
                value: '501-1000',
              },
              {
                label: '1001+',
                value: '1001+',
              },
            ]}
            placeholder="Company size"
            required={true}
            tx={{ ...selectStyles, flexGrow: 1, maxWidth: 'unset', marginBottom: 24 }}
            variant="field-input-smol"
          />
          <SingleSelect
            data-testid="CHILIPIPER_FORM_HEAR_ABOUT"
            label="How did you first hear about us?"
            name="hearAbout"
            options={[
              ...shuffle([
                {
                  label: 'Event (virtual or IRL)',
                  value: 'Event (virtual or IRL)',
                },
                {
                  label: 'Podcast',
                  value: 'Podcast',
                },
                {
                  label: 'Twitter',
                  value: 'Twitter',
                },
                {
                  label: 'LinkedIn',
                  value: 'LinkedIn',
                },
                {
                  label: 'Newsletter/Email',
                  value: 'Newsletter/Email',
                },
                {
                  label: 'Blog or publication',
                  value: 'Blog or publication',
                },
                {
                  label: 'Referred by someone',
                  value: 'Referred by someone',
                },
                {
                  label: 'Advertisement',
                  value: 'Advertisement',
                },
                {
                  label: 'Search engine',
                  value: 'Search engine',
                },
              ]),
              {
                label: 'Other',
                value: 'Other',
              },
            ]}
            required={false}
            tx={{ ...selectStyles }}
            variant="field-input-smol"
          />
          {values.hearAbout === 'Other' && (
            <DeprecatedInput
              data-testid="CHILIPIPER_FORM_HEAR_ABOUT_OTHER"
              isLabelHidden={true}
              label="Please add more details"
              name="hearAboutOther"
              placeholder="Please add more details"
              required
              tx={{ width: '100%', minWidth: 'unset', marginTop: 16 }}
              type="email"
              variant="field-input-smol"
            />
          )}
          <Button
            css={{ display: 'block', width: '100%', marginTop: 32 }}
            data-testid="CHILIPIPER_FORM_SUBMIT"
            size="large"
            type="submit"
          >
            {buttonText}
          </Button>
          <Text as="p" css={{ marginTop: 24, textAlign: 'center' }} size="ui-14">
            By submitting the form, you agree to our{' '}
            <Anchor href={Routes.terms} css={{ fontSize: 'inherit' }}>
              Terms of Service
            </Anchor>{' '}
            and acknowledge our{' '}
            <Anchor href={Routes.privacy} css={{ fontSize: 'inherit' }}>
              Privacy Policy
            </Anchor>
            .
          </Text>
        </Form>
      )}
    </Formik>
  );
};
