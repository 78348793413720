import { Box, Text } from '@air/marketing-primitives';
import { chunk } from 'lodash';
import { cloneElement, ReactElement } from 'react';

import { Container } from '~/old-marketing/components/Container';
import { StyledHeaderMenu } from '~/old-marketing/components/Header/HeaderMenu.styles';
import { HeaderMenuBackground } from '~/old-marketing/components/Header/HeaderMenuBackground';
import { HeaderMenuItem } from '~/old-marketing/components/Header/HeaderMenuItem';
import { HeaderNavigationItem } from '~/old-marketing/constants/navigation';
import { HEADER_MENU } from '~/old-marketing/constants/testIds';

export const TEMPLATE_COLUMNS = {
  enterprise: 'minmax(auto, 548px) minmax(auto, 234px) minmax(auto, 300px)',
  product: 'minmax(auto, 350px) minmax(auto, 400px) minmax(auto, 376px)',
  resources: 'minmax(auto, 170px) minmax(auto, 170px) minmax(auto, 200px) minmax(auto, 400px)',
  'why-air': 'minmax(auto, 250px) minmax(auto, 160px) minmax(auto, 220px) minmax(auto, 376px)',
};

export type HeaderMenuProps = Pick<HeaderNavigationItem, 'columns'> & {
  color?: string;
  onDemoDialogOpen?: () => void;
  template: 'enterprise' | 'product' | 'resources';
};

export const HeaderMenu = ({ color, columns, onDemoDialogOpen, template = 'product' }: HeaderMenuProps) => {
  return (
    <StyledHeaderMenu
      animate={{ rotateX: 0, opacity: 1 }}
      data-testid={HEADER_MENU}
      initial={{ rotateX: -10, opacity: 0 }}
      transition={{ type: 'spring', stiffness: 100 }}
    >
      <Container
        tx={{
          display: 'grid',
          gridTemplateColumns: TEMPLATE_COLUMNS[template],
          gridColumnGap: 20,
          justifyContent: 'space-evenly',
        }}
      >
        {columns?.map((column, index) => {
          return (
            <Box
              css={{
                borderRight: column.hasDivider ? '1px solid' : undefined,
                borderRightColor: '$pigeon100',
                paddingRight: column.hasDivider ? 20 : 0,

                '&:last-child': {
                  paddingRight: 0,
                },
                ...column.css,
              }}
              key={`menu-column-${index}`}
            >
              <Text css={{ marginBottom: 20, color: '$pigeon400' }} size="ui-14" transform="uppercase">
                {column.name}
              </Text>
              <Box css={{ display: 'flex' }}>
                {'children' in column ? (
                  <>
                    {onDemoDialogOpen
                      ? cloneElement(column.children as ReactElement, { onDemoDialogOpen })
                      : column.children}
                  </>
                ) : (
                  <>
                    {(chunk(column.items, column?.chunkSize ?? 4) || []).map((chunk, index) => (
                      <Box
                        css={{
                          width: '100%',
                          marginRight: 12,

                          '&:last-child': { marginRight: 0 },
                        }}
                        key={`menu-column-chunk-${index}`}
                      >
                        {chunk?.map((item, index) => (
                          <HeaderMenuItem
                            adornment={item.adornment}
                            css={{ marginBottom: 12 }}
                            description={item.description}
                            href={item.href}
                            key={`menu-column-item-${index}`}
                            name={item.name}
                          />
                        ))}
                      </Box>
                    ))}
                  </>
                )}
              </Box>
            </Box>
          );
        })}
      </Container>
      <HeaderMenuBackground css={{ color }} />
    </StyledHeaderMenu>
  );
};

HeaderMenu.toString = () => `.${StyledHeaderMenu.className}`;
