import { ChevronRight } from '@air/icons';
import { useTrackClickedActionItem } from '@air/marketing-analytics';
import { Box, BoxProps, IconButton } from '@air/zephyr';
import { motion, useReducedMotion } from 'framer-motion';
import { Fragment, useEffect, useState } from 'react';

import { HeaderMobileMenuNavigationItem } from '~/old-marketing/components/Header/HeaderMobileMenu/HeaderMobileMenuNavigationItem';
import { HeaderMobileMenuPanel } from '~/old-marketing/components/Header/HeaderMobileMenu/HeaderMobileMenuPanel';
import { HeaderMobileMenuSignUpBanner } from '~/old-marketing/components/Header/HeaderMobileMenu/HeaderMobileMenuSignUpBanner';
import { HeaderNavigationItem } from '~/old-marketing/constants/navigation';
import { AuthRoutes } from '~/old-marketing/constants/Routes';

const HEADER_MOBILE_MENU_ANIMATION_VARIANTS = {
  closed: {
    height: 0,
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
  },
  opened: {
    display: 'flex',
    height: '100%',
    opacity: 1,
  },
};

const HEADER_MOBILE_MENU_REDUCED_ANIMATION_VARIANTS = {
  closed: {
    opacity: 0,
    height: '100%',
    transitionEnd: {
      display: 'none',
    },
  },
  opened: {
    height: '100%',
    opacity: 1,
  },
};

export interface HeaderMobileMenuProps extends Pick<BoxProps, 'tx'> {
  isOpened: boolean;
  navigation: HeaderNavigationItem[];
}

export const HeaderMobileMenu = ({ isOpened, navigation, tx }: HeaderMobileMenuProps) => {
  const shouldReduceMotion = useReducedMotion();
  const [openedPanel, setOpenedPanel] = useState(0);
  const { trackClickedActionItem } = useTrackClickedActionItem();

  /**
   * Resets panel when `isOpened` state is changed.
   */
  useEffect(() => {
    setOpenedPanel(0);
  }, [isOpened]);

  return (
    <Box
      animate={isOpened ? 'opened' : 'closed'}
      as={motion.div}
      initial={{ height: 0, opacity: 0 }}
      transition={{ duration: 0.4, type: 'tween' }}
      tx={{
        zIndex: 3,
        position: 'fixed',
        inset: 0,
        display: 'flex',
        backgroundColor: 'white',
        pointerEvents: !isOpened ? 'none' : 'unset',
        height: '100%',
        ...tx,
      }}
      variants={
        shouldReduceMotion ? HEADER_MOBILE_MENU_REDUCED_ANIMATION_VARIANTS : HEADER_MOBILE_MENU_ANIMATION_VARIANTS
      }
    >
      <Box
        tx={{
          flexGrow: 1,
          height: 'calc(100% - 80px)',
          mt: 80,
          px: 32,
          pt: 40,
          pb: 40,
          overflowY: 'scroll',
          WebkitOverflowScrolling: 'touch',
        }}
      >
        <Box as="nav">
          {navigation?.map((navigationItem, index) => {
            if (!!navigationItem.columns?.length) {
              return (
                <Fragment key={index}>
                  <Box tx={{ display: 'flex', alignItems: 'center' }}>
                    <HeaderMobileMenuNavigationItem
                      href={navigationItem.href}
                      onClick={() => {
                        if (navigationItem.href) {
                          trackClickedActionItem({
                            destination: navigationItem.href,
                            destinationType: 'internal',
                            location: window.location.href,
                            name: navigationItem.name,
                            pathName: window.location.pathname,
                            zone: 'marketing',
                          });
                        } else {
                          setOpenedPanel(index + 1);
                        }
                      }}
                    >
                      {navigationItem.name}
                    </HeaderMobileMenuNavigationItem>
                    <IconButton
                      icon={ChevronRight}
                      hasTooltip={false}
                      onClick={() => setOpenedPanel(index + 1)}
                      tx={{ flexShrink: 0, ml: 12, color: 'jay700' }}
                      size="medium"
                      variant="button-ghost-grey"
                    >
                      {`Expand ${navigationItem.name} panel`}
                    </IconButton>
                  </Box>

                  <HeaderMobileMenuPanel
                    isOpened={openedPanel === index + 1}
                    name={navigationItem.name}
                    navigation={navigationItem.columns}
                    onClose={() => setOpenedPanel(0)}
                  />
                </Fragment>
              );
            }

            return (
              <HeaderMobileMenuNavigationItem
                href={navigationItem.href}
                key={index}
                onClick={() => {
                  if (navigationItem.href) {
                    trackClickedActionItem({
                      destination: navigationItem.href,
                      destinationType: 'internal',
                      location: window.location.href,
                      name: navigationItem.name,
                      pathName: window.location.pathname,
                      zone: 'marketing',
                    });
                  }
                }}
              >
                {navigationItem.name}
              </HeaderMobileMenuNavigationItem>
            );
          })}

          <Box tx={{ backgroundColor: 'pigeon100', width: 40, height: 1, my: 40 }} />

          <HeaderMobileMenuNavigationItem
            href={AuthRoutes.login}
            onClick={() => {
              trackClickedActionItem({
                destination: AuthRoutes.login,
                destinationType: 'internal',
                location: window.location.href,
                name: 'Log in',
                pathName: window.location.pathname,
                zone: 'marketing',
              });
            }}
          >
            Log in
          </HeaderMobileMenuNavigationItem>
        </Box>
        <HeaderMobileMenuSignUpBanner />
      </Box>
    </Box>
  );
};
