import React from 'react';

import * as StyledHeaderMenuBackground from '~/old-marketing/components/Header/HeaderMenuBackground.styles';
import { HEADER_MENU_BACKGROUND } from '~/old-marketing/constants/testIds';

export type HeaderMenuBackgroundProps = Pick<StyledHeaderMenuBackground.RootProps, 'css'>;

export const HeaderMenuBackground = ({ css }: HeaderMenuBackgroundProps) => {
  return (
    <StyledHeaderMenuBackground.Root
      data-testid={HEADER_MENU_BACKGROUND}
      css={css}
      viewBox="0 0 1668 740"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1667 582.167C1667 495.99 1597.07 425.88 1511.1 425.88H1432.43C1368.81 425.88 1317.33 374.271 1317.33 310.49V303.674C1317.33 136.189 1181.83 0.837402 1015.24 0.837402C848.177 0.837402 713.164 136.676 713.164 303.674C713.164 384.496 647.599 450.224 566.98 450.224H338.719C300.352 450.224 269.27 481.384 269.27 519.847C269.27 551.494 243.53 577.785 211.476 577.785H78.8906C35.6668 577.785 0.699219 612.84 0.699219 656.172V658.607C0.699219 702.912 36.6381 739.428 81.3189 739.428H1511.1C1597.07 738.941 1667 668.831 1667 582.167Z"
        fill="currentColor"
      />
    </StyledHeaderMenuBackground.Root>
  );
};
