/**
 * These are routes that are shared with app. Make sure to keep this up to date with app.
 */

export const APP_BASE = 'https://app.air.inc';
export const MARKETING_BASE = 'https://air.inc';

export const SharedRoutes = {
  appStore: 'https://itunes.apple.com/app/apple-store/id1244558410?pt=118626354&ct=aircamwebsite&mt=8',
  helpCenter: 'https://help.air.inc',
  playStore: 'https://play.google.com/store/apps/details?id=com.airapp.android',
  releases: 'https://www.notion.so/aircamera/What-s-New-1d5da1c7779a4e71a94971834d5cdf0e',
  status: 'https://status.air.inc/',
};

export const AppRoutes = {
  library: `${APP_BASE}/library`,
};

/**
 * These are social media routes.
 */
export const SocialMediaRoutes = {
  github: 'https://github.com/AirLabsTeam',
  instagram: 'https://instagram.com/air.hq',
  linkedIn: 'https://www.linkedin.com/company/airhq/',
  tiktok: 'https://www.tiktok.com/@meetair',
  twitter: 'https://twitter.com/airHQ',
  youtube: 'https://www.youtube.com/channel/UCUgwosdkOa7oWs5wacfSTWQ',
};

export const AuthRoutes = {
  signUp: `${APP_BASE}/sign-up`,
  login: `${APP_BASE}/login`,
};

export const Routes = {
  airShop: 'https://shop.air.inc/',
  about: `${MARKETING_BASE}/about`,
  adobePremierePro: `${MARKETING_BASE}/adobe-premiere-pro`,
  adobePremiereProDownload: 'https://partners.adobe.com/exchangeprogram/creativecloud/exchange.details.107604.html',
  agencies: `${MARKETING_BASE}/agencies`,
  blog: `${MARKETING_BASE}/blog`,
  careers: 'https://apply.workable.com/air/',
  contentCleanersDigitalLibraryOrganizers: `${MARKETING_BASE}/content-cleaners-digital-library-organizers`,
  creatorProgram: `${MARKETING_BASE}/creator-program`,
  comparison: `${MARKETING_BASE}/comparison`,
  comparisonBrandExperience: `${MARKETING_BASE}/comparison/brand-experience`,
  comparisonCloudStorageAlternative: `${MARKETING_BASE}/comparison/cloud-storage-alternative`,
  comparisonDigitalAssetManagement: `${MARKETING_BASE}/comparison/digital-asset-management`,
  comparisonDropboxAlternative: `${MARKETING_BASE}/comparison/dropbox-alternative`,
  comparisonGoogleDriveAlternative: `${MARKETING_BASE}/comparison/google-drive-alternative`,
  comparisonBox: `${MARKETING_BASE}/comparison/box-alternative`,
  comparisonDAM: `${MARKETING_BASE}/comparison/digital-asset-management`,
  comparisonHardDrives: `${MARKETING_BASE}/comparison/hard-drives`,
  culture: `${MARKETING_BASE}/culture`,
  cultureManagement: `${MARKETING_BASE}/culture/management`,
  culturePolicies: `${MARKETING_BASE}/culture/policies`,
  customerStories: `${MARKETING_BASE}/customer-stories`,
  demo: 'https://calendly.com/john_air/meetjohn',
  departmentDesignCreative: `${MARKETING_BASE}/department/design-creative`,
  departmentMarketing: `${MARKETING_BASE}/department/marketing`,
  departmentGrowth: `${MARKETING_BASE}/department/growth`,
  desktop: `${MARKETING_BASE}/air-lift`,
  diversity: `${MARKETING_BASE}/culture/diversity`,
  download: `${MARKETING_BASE}/download`,
  enterprise: `${MARKETING_BASE}/enterprise`,
  features: `${MARKETING_BASE}/features`,
  featuresDownloads: `${MARKETING_BASE}/features/downloads`,
  featuresDynamicFeedback: `${MARKETING_BASE}/features/dynamic-feedback`,
  featuresFlexibleOrganization: `${MARKETING_BASE}/features/flexible-organization`,
  featuresKanban: `${MARKETING_BASE}/features/kanban`,
  featuresSmartSearch: `${MARKETING_BASE}/features/smart-search`,
  featuresVersions: `${MARKETING_BASE}/features/versions`,
  featuresVisualWorkspace: `${MARKETING_BASE}/features/visual-workspace`,
  inclusion: `${MARKETING_BASE}/culture/inclusion`,
  industry: `${MARKETING_BASE}/industry`,
  industryAgencies: `${MARKETING_BASE}/industry/agencies`,
  industryAthletics: `${MARKETING_BASE}/industry/athletics`,
  industryEducation: `${MARKETING_BASE}/industry/education`,
  industryMedia: `${MARKETING_BASE}/industry/media`,
  industryNonprofits: `${MARKETING_BASE}/industry/nonprofits`,
  industryRetail: `${MARKETING_BASE}/industry/retail`,
  integrations: `${MARKETING_BASE}/integrations`,
  iphone: 'https://apps.apple.com/app/apple-store/id1244558410',
  logos: `${MARKETING_BASE}/logos`,
  migrate: `${MARKETING_BASE}/switch`,
  operators: `${MARKETING_BASE}/operators`,
  pricing: `${MARKETING_BASE}/pricing`,
  privacy: `${MARKETING_BASE}/legal/privacy`,
  releaseNotes: 'https://www.notion.so/airinc/What-s-New-on-Air-1d5da1c7779a4e71a94971834d5cdf0e',
  resources: `${MARKETING_BASE}/resources`,
  root: `${MARKETING_BASE}/`,
  security: `${MARKETING_BASE}/security`,
  store: 'https://shop.air.inc',
  terms: `${MARKETING_BASE}/legal/terms`,
  tour: `${MARKETING_BASE}/tour`,
  tourCreativeApprovals: `${MARKETING_BASE}/tour/creative-approvals`,
  tourCreativeRequests: `${MARKETING_BASE}/tour/creative-requests`,
  tourOnboardFreelancers: `${MARKETING_BASE}/tour/onboard-freelancers`,
  tourRightsManagement: `${MARKETING_BASE}/tour/rights-management`,
  worthlessStudios: `${MARKETING_BASE}/blog/worthless-studios`,

  /**
   * Landing page routes. Subject to change
   */
  semlp1forma: `${MARKETING_BASE}/landing/sem-lp-form-1`,
  semlp1formb: `${MARKETING_BASE}/landing/sem-lp-form-2`,
  semlp1buttona: `${MARKETING_BASE}/landing/sem-lp-button-1`,
  semlp1buttonb: `${MARKETING_BASE}/landing/sem-lp-button-2`,
};

export const BlogRoutes = {
  archive: `${MARKETING_BASE}/blog/archive`,
  categories: `${MARKETING_BASE}/blog/category`,
  home: `${MARKETING_BASE}/blog`,

  /**
   * These are routes that contains a custom page renderer.
   */
  firstagram: `${MARKETING_BASE}/blog/1stagram`,
  nycRooftopBarsRestaurants: `${MARKETING_BASE}/blog/nyc-rooftop-bars-restaurants`,
  nycCreativeCouncil: `${MARKETING_BASE}/blog/nyc-creative-council`,
  sixFeetCalculator: `${MARKETING_BASE}/blog/6feetcalculator`,
  supportNYC: `${MARKETING_BASE}/blog/supportnycdtc`,
  creativeOps: `${MARKETING_BASE}/blog/category/creative-ops`,
  buildingBrands: `${MARKETING_BASE}/blog/category/building-brands`,
  airNews: `${MARKETING_BASE}/blog/category/air-news`,
};
