import { createContext, ReactNode, useContext } from 'react';

const PreviewModeContext = createContext<{ isPreview: boolean } | undefined>(undefined);

export const usePreviewMode = () => {
  const context = useContext(PreviewModeContext);

  if (context === undefined) {
    throw new Error('PreviewModeContext called outside of PreviewModeProvider');
  }

  return { isPreview: context.isPreview };
};

export const PreviewModeProvider = ({ children, value }: { children: ReactNode; value: { isPreview: boolean } }) => {
  return <PreviewModeContext.Provider value={value}>{children}</PreviewModeContext.Provider>;
};
