import { ChilipiperRouter } from '@air/plasmic-chilipiper';
import { Box } from '@air/zephyr';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';

import { Dialog, DialogProps } from '../Dialog';
import { ChilipiperDetailedForm, ChilipiperDetailedFormProps } from '../forms/ChilipiperDetailedForm';

export type DemoDialogProps = Pick<DialogProps, 'isOpen' | 'onDismiss'> &
  Pick<ChilipiperDetailedFormProps, 'leadsource' | 'id'>;

export const DemoDialog = ({ id, isOpen, leadsource, onDismiss }: DemoDialogProps) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <Dialog
          data-testid="DEMO_DIALOG"
          description="Schedule a free demo today. One of our experts will show you how Air works."
          isOpen={isOpen}
          onDismiss={onDismiss}
          title="Let’s talk!"
        >
          <ChilipiperDetailedForm id={id ?? ChilipiperRouter['limit-fields-aug-22']} leadsource={leadsource} />

          <Box
            as={motion.div}
            tx={{ position: 'absolute', bottom: 82, left: -88, display: ['none', 'block'], pointerEvents: 'none' }}
          >
            <Image alt="" height={67} src="/assets/marketing/demo-dialog-cloud-1.svg" width={139} />
          </Box>

          <Box tx={{ position: 'absolute', top: 170, right: -103, display: ['none', 'block'], pointerEvents: 'none' }}>
            <Image alt="" height={65} src="/assets/marketing/demo-dialog-cloud-2.svg" width={137} />
          </Box>

          <Box tx={{ position: 'absolute', top: -60, left: -55, display: ['none', 'block'], pointerEvents: 'none' }}>
            <Image alt="" height={90} src="/assets/marketing/demo-dialog-macaroni.svg" width={65} />
          </Box>
        </Dialog>
      )}
    </AnimatePresence>
  );
};
