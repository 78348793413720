import { Close } from '@air/icons';
import { Box, BoxProps, IconButton, IconButtonProps } from '@air/zephyr';
import {
  DialogContent as ReachDialogContent,
  DialogContentProps as ReachDialogContentProps,
  DialogOverlay as ReachDialogOverlay,
  DialogOverlayProps as ReachDialogOverlayProps,
} from '@reach/dialog';
import { motion, MotionProps } from 'framer-motion';
import { forwardRef, FunctionComponent } from 'react';

const MotionDialogOverlay = motion.custom(ReachDialogOverlay);
const MotionDialogContent = motion.custom(ReachDialogContent);

const DIALOG_OVERLAY_MOTION = {
  exit: {
    opacity: 0,
  },
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
};

export interface DialogOverlayProps extends Pick<BoxProps, 'ref' | 'tx'>, ReachDialogOverlayProps {}

export const DialogOverlay = forwardRef<HTMLDivElement, DialogOverlayProps>(
  ({ tx, ...restOfProps }: DialogOverlayProps, forwardedRef) => {
    return (
      <Box
        animate="animate"
        as={
          MotionDialogOverlay as FunctionComponent<
            ReachDialogContentProps & Omit<MotionProps, keyof ReachDialogContentProps>
          >
        }
        exit="exit"
        initial="initial"
        ref={forwardedRef}
        tx={{
          zIndex: 1001,
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: 'rgba(38, 38, 38, 0.7)',
          overflow: 'auto',
          ...tx,
        }}
        variants={DIALOG_OVERLAY_MOTION}
        {...restOfProps}
      />
    );
  },
);

DialogOverlay.displayName = 'DialogOverlay';

export interface DialogOverlayCloseProps extends Omit<IconButtonProps, 'children' | 'tooltip' | 'icon' | 'ref'> {}

export const DialogOverlayClose = forwardRef<HTMLButtonElement, DialogOverlayCloseProps>(
  ({ tx, ...restOfProps }: DialogOverlayCloseProps, forwardedRef) => {
    return (
      <IconButton
        icon={Close}
        ref={forwardedRef}
        size="large"
        tx={{
          position: 'fixed',
          top: [12, 24],
          right: [12, 24],
          backgroundColor: 'pigeon700',
          color: 'white',
          borderRadius: 100,

          '&:hover': {
            backgroundColor: 'pigeon600',
            color: 'white',
          },

          ...tx,
        }}
        variant="button-ghost-grey"
        {...restOfProps}
      >
        Close modal (ESC)
      </IconButton>
    );
  },
);

DialogOverlayClose.displayName = 'DialogClose';

const DIALOG_CONTENT_MOTION = {
  exit: {
    opacity: 0,
    y: 120,
  },
  initial: {
    opacity: 0,
    y: 120,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
};

export interface DialogContentProps
  extends Pick<BoxProps, 'children' | 'ref' | 'tx'>,
    Omit<ReachDialogContentProps, 'ref'> {}

export const DialogContent = forwardRef<HTMLDivElement, DialogContentProps>(
  ({ children, tx, ...restOfProps }: DialogContentProps, forwardedRef) => {
    return (
      <Box
        animate="animate"
        as={
          MotionDialogContent as FunctionComponent<
            ReachDialogContentProps & Omit<MotionProps, keyof ReachDialogContentProps>
          >
        }
        exit="exit"
        initial="initial"
        transition={{ type: 'spring', bounce: 0.2, duration: 0.4 }}
        tx={{
          position: 'relative',
          backgroundColor: 'white',
          width: '100%',
          maxWidth: 496,
          borderRadius: 8,
          mt: '10%',
          mb: [0, '10%'],
          px: [24, 56],
          py: 56,
          ...tx,
          mx: 'auto',
        }}
        variants={DIALOG_CONTENT_MOTION}
        ref={forwardedRef}
        {...restOfProps}
      >
        {children}
      </Box>
    );
  },
);

DialogContent.displayName = 'DialogContent';
